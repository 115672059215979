import React, { useState } from "react";
import { Grid, Table, Loader, Dimmer, TableRow } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline } from "react-icons/md";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { IngredientTableColView } from "../../../config/constants";
import IngredientEditModal from "../ingredientEdit/IngredientEditModal";
import IngredientDetailsModal from "../ingredientDetails/IngredientDetailsModal";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import {
  useGetIngredientList,
  usePutSupplierData,
} from "../../../api/ingredients";
import PaginationView from "../../../components/pagination/Pagination";
import { successMessage } from "../../../helpers/ErrorHandler";
import IngredientRegisterModalView from "../ingredientRegister/IngredientRegisterModalView";
import { getUserRolesInLocal } from "../../../utils/cacheStorage";
import { ROLES } from "../../../config/permission-maps";

const IngredientList = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [showDeleteIngredientModal, setShowDeleteIngredientModal] =
    useState(false);
  const [viewIngredientData, setViewIngrdientData] = useState();
  const [editIngredientData, setEditIngrdientData] = useState();
  const [deleteIngredientData, setDeleteIngrdientData] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(10);
  const localUserRole = getUserRolesInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  const defaultSupplierData = {
    ingredient_id: null,
    ingredient_name: null,
    supplier_id: null,
    supplier_name: null,
    supplierIngredientDetails: null,
    supplierAsUser: false,
    ingredientProductIds: [],
    supplierProductIds: [],
    supplierIngredientIds: [],
  };

  const defaultIngredientData = {
    ingredient_id: null,
    ingredient_name: null,
    product_id: null,
    product_name: null,
    supplier_id: null,
    supplier_name: null,
    supplierIngredientDetails: null,
    ingredientProductIds: [],
    supplierProductIds: [],
    supplierIngredientIds: [],
  };

  const {
    data: ingredientList,
    isLoading: isIngredientListLoading,
    refetch: refetchIngredients,
  } = useGetIngredientList(pageNumber, pageLimit);

  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();

  const handlePagination = (currentPage: any) => {
    setPageNumber(currentPage);
  };

  const handlePageSize = (pageSize: any) => {
    setPageLimit(pageSize);
  };

  const getIngredientDetails = (selectedIngredient: any) => {
    setViewIngrdientData(selectedIngredient);
    setVisibleDetailsModal(true);
  };

  const handleIngredientDelete = (detailsToDelete: any) => {
    setDeleteIngrdientData(detailsToDelete);
    setShowDeleteIngredientModal(true);
  };

  const getIngredientForEditDetails = (detailsToEdit: any) => {
    setEditIngrdientData(detailsToEdit);
    setVisibleEditModal(true);
  };

  const deleteSupplier = () => {
    let supplierIngredient = deleteIngredientData.supplierIngredientIds.filter(
      (e: any) => e != deleteIngredientData.ingredient_id
    );
    let supplierUpdate = {
      _id: deleteIngredientData.supplier_id,
      supplier_name: deleteIngredientData.supplier_name,
      ingredientIds: supplierIngredient ? supplierIngredient : [],
    };
    mutatePutSupplier(supplierUpdate, {
      onSuccess(data: any) {
        setShowDeleteIngredientModal(false);
        successMessage("Supplier removed from ingredient successfully");
        setDeleteIngrdientData({});
      },
    });
  };

  const groupIngredientData = (data: any) => {
    let groupIngredientData = data.reduce((currentType: any, data: any) => {
      let checkDataIsAvailable = currentType?.find(
        (other: any) => other.key == data.ingredient_name
      );
      if (currentType?.length == 0) {
        let ingredientData = {
          key: data.ingredient_name,
          value: [data],
        };
        return [...currentType, ingredientData];
      } else if (checkDataIsAvailable) {
        let currentIndex = currentType?.findIndex(
          (other: any) => other.key == data.ingredient_name
        );
        let updatedArray = [];

        const updateArray = [...currentType[currentIndex].value, data];
        const updatedObject = {
          ...currentType[currentIndex],
          value: updateArray,
        };
        updatedArray = [
          ...currentType.slice(0, currentIndex),
          updatedObject,
          ...currentType.slice(currentIndex + 1),
        ];
        return updatedArray;
      } else {
        let ingredientData = {
          key: data.ingredient_name,
          value: [data],
        };

        return [...currentType, ingredientData];
      }
    }, []);

    return groupIngredientData;
  };

  const loadTableData = () => {
    const newData = ingredientList?.result?.map(
      (ingredient: any, index: any) => {
        if (ingredient?.suppliers.length == 0) {
          const productSuppliers = {
            ...defaultSupplierData,
            ingredient_id: ingredient?._id,
            ingredient_name: ingredient?.ingredient_name,
          };
          return productSuppliers;
        } else if (ingredient?.products.length == 0) {
          return ingredient?.suppliers?.map((supplier: any, index: any) => {
            const productSuppliers = {
              ingredient_id: ingredient?._id,
              ingredient_name: ingredient?.ingredient_name,
              supplier_id: supplier?._id,
              supplierAsUser: supplier?.is_supplier_as_user || false,
              supplier_name: supplier?.supplier_name,
              supplierIngredientDetails:
                supplier?.supplierIngredientDetails?.find(
                  (ingDetail: any) =>
                    ingDetail?.ingredient_id === ingredient?._id
                ),
              ingredientProductIds: ingredient?.productIds,
              supplierProductIds: supplier?.productIds,
              supplierIngredientIds: supplier?.ingredientIds,
            };
            return productSuppliers;
          });
        } else {
          return ingredient?.products?.map((product: any, index: any) => {
            return ingredient?.suppliers?.map((supplier: any, index: any) => {
              const productSuppliers =
                supplier?.productIds?.indexOf(product?._id) > -1
                  ? {
                      ingredient_id: ingredient?._id,
                      ingredient_name: ingredient?.ingredient_name,
                      product_id: product?._id,
                      product_name: product?.product_name,
                      supplier_id: supplier?._id,
                      supplierAsUser: supplier?.is_supplier_as_user || false,
                      supplier_name: supplier?.supplier_name,
                      supplierIngredientDetails:
                        supplier?.supplierIngredientDetails?.find(
                          (ingDetail: any) =>
                            ingDetail?.ingredient_id === ingredient?._id
                        ),
                      ingredientProductIds: ingredient?.productIds,
                      supplierProductIds: supplier?.productIds,
                      supplierIngredientIds: supplier?.ingredientIds,
                    }
                  : null;
              return productSuppliers;
            });
          });
        }
      }
    );

    const formattedIngredientList = newData
      ?.flat(Infinity)
      ?.filter((data: any) => data !== null);
    let groupData = groupIngredientData(formattedIngredientList);

    return groupData?.map((data: any, index: any) => {
      let rowNumber = data?.value?.length.toString() || 0;

      return (
        <>
          {data?.value?.map((dataView: any, index: any) => {
            let hideEditCol =
              dataView?.product_name || dataView?.supplier_name ? false : true;
            let dataType = dataView?.supplierIngredientDetails
              ? dataView.supplierIngredientDetails.data_type === "SUPPLIER"
                ? "SPECIFIC"
                : dataView.supplierIngredientDetails.data_type
              : "";

            return index == 0 ? (
              <TableRow key={index}>
                <Table.Cell rowSpan={rowNumber}>{data.key}</Table.Cell>
                <Table.Cell>{dataView?.product_name || ""}</Table.Cell>
                <Table.Cell>{dataView?.supplier_name || ""}</Table.Cell>
                <Table.Cell>{dataType}</Table.Cell>
                <Table.Cell>
                  {`${dataView?.supplierIngredientDetails?.unit_size || ""} ${
                    dataView?.supplierIngredientDetails?.unit_type || ""
                  }`}
                </Table.Cell>
                <Table.Cell>
                  {checkIsUserSupplier &&
                  dataView?.supplierIngredientDetails?.climate_impact
                    ? "Calculated: Upgrade to see results"
                    : dataView?.supplierIngredientDetails?.climate_impact ||
                      "Calculation is in progress"}
                </Table.Cell>
                <Table.Cell>
                  {!hideEditCol ? (
                    <Grid>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="align-content-center"
                      >
                        <TbFileSearch
                          cursor={"pointer"}
                          onClick={() => {
                            getIngredientDetails(dataView);
                          }}
                          size={24}
                          color="var(--tableEditIcon)"
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="align-content-center"
                      >
                        <MdModeEditOutline
                          cursor={"pointer"}
                          onClick={() => {
                            getIngredientForEditDetails(dataView);
                          }}
                          size={24}
                          color="var(--tableEditIcon)"
                        />
                      </Grid.Column>
                    </Grid>
                  ) : null}
                </Table.Cell>
              </TableRow>
            ) : (
              <TableRow>
                <Table.Cell>{dataView?.product_name || ""}</Table.Cell>
                <Table.Cell>{dataView?.supplier_name || ""}</Table.Cell>
                <Table.Cell>{dataType}</Table.Cell>
                <Table.Cell>
                  {`${dataView?.supplierIngredientDetails?.unit_size || ""} ${
                    dataView?.supplierIngredientDetails?.unit_type || ""
                  }`}
                </Table.Cell>
                <Table.Cell>
                  {checkIsUserSupplier &&
                  dataView?.supplierIngredientDetails?.climate_impact
                    ? "Calculated: Upgrade to see results"
                    : dataView?.supplierIngredientDetails?.climate_impact ||
                      "Calculation is in progress"}
                </Table.Cell>
                <Table.Cell key={index}>
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      className="align-content-center"
                    >
                      <TbFileSearch
                        cursor={"pointer"}
                        onClick={() => {
                          getIngredientDetails(dataView);
                        }}
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      className="align-content-center"
                    >
                      <MdModeEditOutline
                        cursor={"pointer"}
                        onClick={() => {
                          getIngredientForEditDetails(dataView);
                        }}
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </TableRow>
            );
          })}
        </>
      );
    });
  };

  // Loading state check
  if (isIngredientListLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Ingredients"} />
      <CustomButton
        theme="green"
        title="New Ingredient"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable
            tableHeaderData={IngredientTableColView}
            singleLine={false}
          >
            {loadTableData()}
          </CommonTable>
          <PaginationView
            changePageSize={handlePageSize}
            pageSize={pageLimit}
            totalPages={Math.ceil(ingredientList?.total / pageLimit)}
            currentPageNo={pageNumber}
            currentSelectPage={handlePagination}
          />
        </Grid.Column>
      </Grid>
      {visibleModal ? (
        <IngredientRegisterModalView
          visibleModal={visibleModal}
          setVisibleModal={() => setVisibleModal(false)}
          ingredientList={ingredientList?.result || []}
          refetchIngredients={refetchIngredients}
        />
      ) : null}
      {visibleEditModal ? (
        <IngredientEditModal
          ingredientIdToUpdate={editIngredientData}
          visibleModal={visibleEditModal}
          setVisibleModal={() => setVisibleEditModal(false)}
          refetchIngredients={refetchIngredients}
        />
      ) : null}
      {visibleDetailsModal ? (
        <IngredientDetailsModal
          visibleModal={visibleDetailsModal}
          setVisibleModal={() => setVisibleDetailsModal(false)}
          ingredientIdToUpdate={viewIngredientData}
        />
      ) : null}
      <ConfirmModal
        viewModal={showDeleteIngredientModal}
        closeModal={() => setShowDeleteIngredientModal(false)}
        cancel={() => {
          setShowDeleteIngredientModal(false);
          setDeleteIngrdientData({});
        }}
        approve={() => {
          deleteSupplier();
        }}
        title="Delete Ingredient Data"
        subTitle="Are you sure you want to delete the ingredient?"
      />
    </>
  );
};

export default IngredientList;
