import React, { useEffect, useState } from "react";

import { Button, Modal } from "semantic-ui-react";
import { DropDown } from "../../components";
import { useGetProductCalculateSales, useGetProductSalesUnits } from "../../api/product";
import { usePatchShareDrawing } from "../../api/supplierDrawingTool";
import { isEmpty } from "lodash";
import { useDrawingRequestUpdate } from "../../api/notification";
import { SupplierRequestStatus } from "../../config/constants";

const ShareMappingConfirm = ({
  visibleModal,
  setVisibleModal,
  requestId,
  ingredientId,
}: any) => {
  const [searchKey, setSearchKey] = useState("");
  const [salesUnitId, setSalesUnitId] = useState<string>();

  useEffect(() => {
    setSearchKey("");
    setSalesUnitId("");
  }, [visibleModal]);

  const updateSalesUnitId = (data: any) => {
    const id = data.value as string;
    setSalesUnitId(id);
  };

  const {
    data: productSalesUnitsData,
    isLoading: isProductSalesUnitsLoading,
    isSuccess: isSuccessSalesUnits,
  } = useGetProductCalculateSales({ searchKey });

  const { data, mutate, isLoading } = usePatchShareDrawing();
  const { mutate: drawingRequestUpdate } = useDrawingRequestUpdate();

  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  const handleBottomButton = () => {
    return (
      <>
        <Button negative onClick={() => setVisibleModal()}>
          Cancel
        </Button>
        <Button
          disabled={isEmpty(salesUnitId)}
          positive
          onClick={() => {
            shareDrawingData();
          }}
        >
          Share
        </Button>
      </>
    );
  };

  const shareDrawingData = () => {
    const shareData = {
      requestId,
      salesUnitId,
    };
    mutate(shareData, {
      onSuccess(data, variables, context) {
        let status = SupplierRequestStatus.Completed
        drawingRequestUpdate({ ingredientId: ingredientId, status });
        setVisibleModal();
      },
    });
  };

  return (
    <Modal
      centered={false}
      size={"small"}
      open={visibleModal}
      onClose={() => setVisibleModal()}
      dimmer="blurring"
    >
      <Modal.Header>Share Mapping Data</Modal.Header>
      <Modal.Content>
        <DropDown
          required={true}
          labelName="Product Name*"
          placeholder="Product Name"
          currentData={productSalesUnits}
          defaultValue={salesUnitId}
          handleChangeState={(e: any, data: any) => {
            updateSalesUnitId(data);
          }}
          onSearchChange={(e: any, data: any) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isProductSalesUnitsLoading}
          search
        />
      </Modal.Content>
      <Modal.Actions>{handleBottomButton()}</Modal.Actions>
    </Modal>
  );
};

export default ShareMappingConfirm;
