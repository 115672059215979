import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { successMessage } from "../../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi } from "../apiService";

interface GetProductSimulationsOptions {
  pageNumber: number;
  pageSize: number;
  productId?: string;
  salesUnitId?: string;
}

export const useCreateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/simulation-drawing-tool`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSimulationDrawing = (
  params: GetProductSimulationsOptions
) => {
  return useQuery<any, ApiServiceErr>(
    ["getSimulationDrawingPageData", params],
    async () => {
      const response = await axiosApi.get("/simulation-drawing-tool", {
        params,
      });
      return response.data;
    },
    {
      enabled: !!params.salesUnitId && !!params.productId,
    }
  );
};

export const useGetSimulationDrawingById = (
  simulationDrawingId: string | null
) =>
  useQuery<any, ApiServiceErr>(
    [simulationDrawingId, "useGetSimulationDrawingById"],
    async () => {
      const response = await axiosApi.get(
        `/simulation-drawing-tool/${simulationDrawingId}`
      );
      return response.data;
    },
    {
      enabled: !!simulationDrawingId,
    }
  );

export const useUpdateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(
    async ({ data, simulationDrawingId }) => {
      const response = await axiosApi.put(
        `/simulation-drawing-tool/${simulationDrawingId}`,
        data
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );

export const usePutSimulationMappingDataToQueue = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (drawingId) => {
    const response = await axiosApi.put(
      `/simulation-drawing-tool/queue/${drawingId}`
    );
    successMessage(response.data);
    return response.data;
  }, opt);
