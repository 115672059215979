import React from "react";

import SupplierList from "../screens/supplier/supplierList/SupplierList";
import { adminAccess, userAccess, supplierAccess } from "./useRoleAccess";

export const SupplierRoutes = [
  {
    path: "/supplier",
    route: <SupplierList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
];
