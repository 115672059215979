import React, { useState } from "react";
import { Grid, Message, Image } from "semantic-ui-react";
import { CommonModal } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { useGetIngredientPackaging } from "../../../api/ingredients";
import IngredientDataRegister from "../ingredientRegister/IngredientDataRegister";
import SupplerRegister from "../ingredientRegister/SupplierRegister";
import IngredientPackagingView from "../ingredientRegister/IngredientPackageing";
import { useGetMappingImageUri } from "../../../api/drawingTool";
import { ROLES } from "../../../config/permission-maps";
import { getUserRolesInLocal } from "../../../utils/cacheStorage";

const IngredientDetailsModal = ({
  ingredientIdToUpdate,
  visibleModal,
  setVisibleModal,
}: any) => {
  const { height } = useWindowDimensions();

  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient: false,
  });

  let ingredientId = ingredientIdToUpdate?.ingredient_id || null;
  let supplierId = ingredientIdToUpdate?.supplier_id || null;

  const packageDataView = viewPackage.enableIngredient ? true : false;
  const localUserRole = getUserRolesInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: visibleModal,
  };

  const { data: signedUrlData } = useGetMappingImageUri(
    ingredientIdToUpdate?.supplierIngredientDetails?.product_summary?.imageKey
  );

  const { data: ingredientPackageData, refetch: ingredientPackageDataRefetch } =
    useGetIngredientPackaging(getPackagingData);

  const supplierMappingImpact =
    ingredientIdToUpdate?.supplierIngredientDetails?.product_summary
      ?.displayTotalImpactClimateChange || null;

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title="Ingredient Details"
    >
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <div
            style={{
              height: height * 0.9 - 50,
              overflowX: "hidden",
            }}
          >
            <IngredientDataRegister
              ingredientListByProduct={[]}
              currentIngredientData={ingredientIdToUpdate}
              disableEdit={true}
            />
            <SupplerRegister
              ingredientSupplierList={[]}
              ingredientData={ingredientIdToUpdate}
              supplierCurrentData={() => {}}
              ingredientList={[]}
              disableEdit={true}
              dataView={true}
              changeDataIngredient={setViewPackage}
            />
            {packageDataView ? (
              <IngredientPackagingView
                ingredientId={ingredientId}
                supplierId={supplierId}
                refetchPackagingData={ingredientPackageDataRefetch}
                ingredientPackageData={ingredientPackageData}
                dataView={true}
              />
            ) : null}
            {supplierMappingImpact ? (
              <Message className="climateImpactMessage">
                Total impact climate change :{" "}
                <span className="climateImpactValue">
                  {checkIsUserSupplier
                    ? "Calculated: Upgrade to see results"
                    : supplierMappingImpact}
                </span>
              </Message>
            ) : null}
            {signedUrlData ? (
              <Image src={signedUrlData?.signedUrl} size="large" rounded />
            ) : null}
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientDetailsModal;
