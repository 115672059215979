import { useState, useContext, useMemo, useRef, useEffect } from "react";
import { DropDown, InputText } from "../../../../../components";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { useReactFlow } from "reactflow";

import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  useGetFarmGateInternalTransportById,
  usePostSimulationFarmGateInternalTransportData,
  usePutSimulationFarmGateInternalTransportData,
  useGetTransportTypesList,
  useGetSimulationFarmGateInternalTransportById,
  useDeleteSimulationFarmGateInternalTransportData,
} from "../../../../../api/cultivations";
import { successMessage } from "../../../../../helpers/ErrorHandler";
import { DrawingContext } from "../../../SimulateDrawingTool";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { valueGraterThanZero } from "../../../../../utils/utils";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const InternalTransportBase = ({ modalData }: any) => {
  const { farmGateId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  // get chart node item from node id

  const { height } = useWindowDimensions();
  const [transportType, setTransportType] = useState("1");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [originalDataToView, setOriginalDataToView] = useState({
    transportName: "",
    avgDistance: "",
    transportType: "",
    weight: "",
  });
  const { data: TransportTypeList } = useGetTransportTypesList();
  const { mutate: postInternalTransportData } =
    usePostSimulationFarmGateInternalTransportData();
  const customAverageDistanceValidation = useRef<HandleValidation>(null);

  const { mutate: putInternalTransportData } =
    usePutSimulationFarmGateInternalTransportData();

  const {
    data: internalTransportData,
    status,
    fetchStatus,
  } = useGetSimulationFarmGateInternalTransportById(
    nodeItem?.data?.reference?.internalTransportId
  );

  const { data: internalOriginalTransportData } =
    useGetFarmGateInternalTransportById(
      internalTransportData?.originalId
    );

  useEffect(() => {
    if (internalOriginalTransportData) {
      const newData = {
        transportName:
          internalOriginalTransportData?.internalTransportName || "",
        avgDistance: internalOriginalTransportData?.averageDistance || "",
        transportType:
          TransportTypeList?.find((tr: any) => {
            return (
              tr.value == internalOriginalTransportData?.transportationType
            );
          })?.text || "",
        weight: internalOriginalTransportData?.weight || "",
      };
      setOriginalDataToView(newData);
    }
  }, [internalOriginalTransportData, TransportTypeList]);

  const { mutate: deleteFarmGateInternalTransportData } =
    useDeleteSimulationFarmGateInternalTransportData();

  const values = useMemo(() => {
    setTransportType(`${internalTransportData?.transportationType || "1"}`);
    return {
      internalTransportName:
        originalDataToView?.transportName !== ""
          ? originalDataToView?.transportName
          : internalTransportData?.internalTransportName,
      averageDistance: internalTransportData?.averageDistance || 0,
      transportType: `${internalTransportData?.transportationType || "1"}`,
      weight: internalTransportData?.weight,
    };
  }, [nodeItem, internalTransportData, farmGateId]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  // Update node data
  const updateNodeData = (data: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: `Internal Transport (${data?.internalTransportName})`,
        reference: {
          internalTransportId: data?._id,
        },
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage(
      nodeItem?.data.reference
        ? "Internal Transport updated successfully"
        : "Internal Transport created successfully"
    );
  };

  const onSubmit = (data: any) => {
    // Extract internal transport ID from nodeItem data
    const transportId = nodeItem?.data?.reference?.internalTransportId;

    // Prepare data to submit
    const dataToSubmit = {
      farmGateId: farmGateId,
      internalTransportId: transportId,
      transportData: {
        internalTransportName:
          originalDataToView?.transportName !== ""
            ? originalDataToView?.transportName
            : data?.internalTransportName,
        transportationType: Number(data?.transportType),
        averageDistance: data?.averageDistance,
        weight: data?.weight ?? 0,
        changeStatus: true
      },
    };

    // Define the callback for success
    const onSuccess = (data: any) => {
      // Update node data with the returned data
      updateNodeData(data);
    };

    // Decide whether to use PUT or POST based on the presence of transportId
    if (transportId) {
      putInternalTransportData(dataToSubmit, { onSuccess });
    } else {
      postInternalTransportData(dataToSubmit, { onSuccess });
    }
  };

  const handleDelete = () => {
    const dataToDelete = {
      farmGateId: farmGateId,
      internalTransportId: nodeItem?.data?.reference?.internalTransportId,
    };
    deleteFarmGateInternalTransportData(dataToDelete, {
      onSuccess: (data: any) => {
        setNodeItem(null);
        onNodesDelete();
        successMessage("Internal Transport deleted successfully");
      },
    });

    setVisibleDeleteModal(false);
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (
    nodeItem?.data.reference &&
    status == "loading" &&
    fetchStatus == "fetching"
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const internalTransportNameDisable = !nodeItem?.data?.reference
    ?.internalTransportId
    ? false
    : true;

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 80,
                overflowX: "hidden",
              }}
            >
              <Grid>
                <Grid.Row divided>
                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className={originalDataToView?.transportName ? "mb-3" : "mb-4"}
                    >
                      <p className="mb-0">Internal Transport Name</p>
                      <p>{originalDataToView?.transportName}</p>
                    </Grid.Column>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className={originalDataToView?.avgDistance ? "mb-3" : "mb-4"}
                    >
                      <p className="mb-0">
                        Average Distance from Filed(s) to Facility (km)
                      </p>
                      <p>{originalDataToView?.avgDistance}</p>
                    </Grid.Column>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className={originalDataToView?.transportType ? "mb-3" : "mb-4"}
                    >
                      <p className="mb-0">Transportation Type</p>
                      <p>{originalDataToView?.transportType}</p>
                    </Grid.Column>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className={originalDataToView?.weight ? "mb-3" : "mb-4"}
                    >
                      <p className="mb-0">Weight(Kg)</p>
                      <p>{originalDataToView?.weight}</p>
                    </Grid.Column>
                  </Grid.Column>
                  <Grid.Column computer={8} tablet={16} mobile={16}>
                      <Grid.Column computer={16} tablet={16} mobile={16}>
                        <InputText
                          labelName={"Internal Transport Name*"}
                          errorMessage="Internal Transport Name is required"
                          name="internalTransportName"
                          register={register}
                          errors={errors.internalTransportName}
                          required
                          disabled={internalTransportNameDisable}
                        />
                      </Grid.Column>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <InputNumber
                        ref={customAverageDistanceValidation}
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || value === 0 ? "" : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        errors={errors?.averageDistance}
                        labelName={
                          "Average Distance from Filed(s) to Facility (km)*"
                        }
                        placeholder="Average Distance from Filed(s) to Facility (km)"
                        name={`averageDistance`}
                        errorMessage={"Please enter average distance"}
                        customRequired={true}
                        valueAsNumber={true}
                      />
                    </Grid.Column>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <DropDown
                        labelName={"Transportation Type"}
                        currentData={TransportTypeList || []}
                        defaultValue={transportType}
                        customGridColumn={"customGridColomnTyp"}
                        handleChangeState={(e: any, { value }: any) => {
                          setTransportType(value);
                          setValue("transportType", value);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <InputNumber
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || value === 0 ? "" : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        labelName={"Weight(Kg)"}
                        placeholder="Weight(Kg)"
                        name={`weight`}
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <MainBottomButtonView
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => {
                customAverageDistanceValidation.current?.checkCustomRequired(
                  getValues().averageDistance
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={handleDelete}
        title="Delete Farm Gate Internal Transport"
        subTitle="Are you sure you want to remove the farm gate internal transport? Please consider you cannot recover these data after removed"
      />
    </Grid>
  );
};
