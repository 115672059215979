import { Grid, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { InputText, CustomButton, CommonModal } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import FacilityEnergy from "../facilityForms/FacilityEnergy";
import FacilityHeatSource from "../facilityForms/FacilityHeatSource";
import FacilityWaterSource from "../facilityForms/FacilityWaterSource";
import FacilityRefrigerant from "../facilityForms/FacilityRefrigerant";

import "./facilityDetails.scss";

const FacilityDetailsModal = ({
  visibleModal,
  setVisibleModal,
  facilityData,
  countries,
}: any) => {
  const { register } = useForm({
    values: facilityData,
  });
  const { height } = useWindowDimensions();

  const energy = facilityData?.energy || [];
  const heatSource = facilityData?.heatSource || [];
  const waterSource = facilityData?.waterSource || [];
  const refrigerantSource = facilityData?.refrigerantSource || [];
  const facilityCountry = facilityData?.facilityCountry;

  const countryName = countries.find(
    (country: any) => country.id === facilityCountry
  )?.country;

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title={facilityData?.facilityName}
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Facility Name*"}
                      placeholder="Facility Name"
                      name="facilityName"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Facility Ownership*"}
                      placeholder="Facility Ownership"
                      name="facilityOwnership"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Facility Type*"}
                      placeholder="Facility Type"
                      name="facilityType"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Address"}
                      placeholder="Address"
                      name="facilityAddress"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <InputText
                      register={register}
                      labelName={"City"}
                      placeholder="City"
                      name="facilityCity"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <InputText
                      labelName={"Country*"}
                      placeholder={countryName}
                      disabled={true}
                    />
                  </Grid.Column>
                </Grid>
                <FacilityEnergy
                  renderTableData={true}
                  addFacilityEnergy={energy || []}
                  currentFacilityData={energy || []}
                />
                <FacilityHeatSource
                  renderTableData={true}
                  addFacilityHeat={heatSource}
                  currentFacilityHeatData={heatSource || []}
                />
                <FacilityWaterSource
                  renderTableData={true}
                  addFacilityWater={waterSource}
                  currentFacilityWaterData={waterSource || []}
                />
                <FacilityRefrigerant
                  renderTableData={true}
                  addFacilityRefrigerant={refrigerantSource}
                  currentFacilityRefrigerantData={refrigerantSource || []}
                />
              </div>
              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Close"}
                    customColumnStyle={"cardInsertSaveOther"}
                    onClick={() => setVisibleModal(false)}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default FacilityDetailsModal;
