import React from "react";

import ProductList from "../screens/product/productList/ProductList";
import { adminAccess, userAccess, supplierAccess } from "./useRoleAccess";

export const ProductRoutes = [
  {
    path: "/product",
    route: <ProductList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
];
