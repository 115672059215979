import React, { useEffect } from "react";
import { Button, Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import {
  InputText,
  InputTextArea,
  CommonTable,
  NewImageUploader,
  Switch,
  CommonModal,
  TitleView,
} from "../../../components";
import defaultUserImage from "../../../assets/images/default_icon.jpeg";
import { PackagingColDetails } from "../../../config/constants";
import "./productDetails.scss";
import { useGetProductById } from "../../../api/product";
import { useNavigate } from "react-router-dom";

const ProductDetailModal = ({
  visibleModal,
  setVisibleModal,
  productId,
}: any) => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const { data: productDetails, isLoading: productDetailsLoading } =
    useGetProductById(productId);

  useEffect(() => {
    setValue("product_name", productDetails?.product_name);
    setValue("registration_number", productDetails?.registration_number);
    setValue("product_other_info", productDetails?.product_other_info);
  }, [productDetails]);

  //product details view table
  const loadTableData = () => {
    return productDetails?.sales_units.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data["sales_unit_name"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["sales_unit_size"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["sales_unit_type"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["ean_barcode"]}</p>
          </Table.Cell>
          <Table.Cell>
            <Button
              className="action_button"
              onClick={() => {
                navigate(`/drawing?salesUnitId=${data?._id}`);
              }}
            >
              View Mapping
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //loading view
  if (productDetailsLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        title="Product Details"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <div className="productDetailsInsert">
              <form>
                <Grid className="productDetailsViewMain">
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.product_name}
                      labelName={"Name"}
                      placeholder="Name"
                      name="product_name"
                      required={true}
                      lableName={"name"}
                      errorMessage={"Please enter product name"}
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.registration_number}
                      labelName={"Register number"}
                      placeholder="Register number"
                      name="registration_number"
                      required={false}
                      disabled={true}
                    />
                  </Grid.Column>

                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <Switch
                      label={"Product Type"}
                      onClick={() => {}}
                      titleOne={"Process Product"}
                      titleTwo={"Farm Product"}
                      titleOneValue={"PROCESS"}
                      titleTwoValue={"FARM"}
                      defaultValue={productDetails?.product_type}
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputTextArea
                      register={register}
                      errors={errors.product_other_info}
                      labelName={"Additional Information"}
                      placeholder="Additional Information"
                      name="product_other_info"
                      customInputValidation={true}
                      disabled={true}
                    />
                  </Grid.Column>
                  {!isEmpty(productDetails?.product_image_path) ? (
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <NewImageUploader
                        defaultUserImage={defaultUserImage}
                        buttonTitle={"Upload an Image"}
                        defaultImage={productDetails?.product_image_path}
                        uploadButtonView={false}
                        deleteButtonView={false}
                      />
                    </Grid.Column>
                  ) : null}
                </Grid>
              </form>
            </div>
          </Grid.Column>

          {productDetails?.sales_units?.length > 0 ? (
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <TitleView title="Packaging Unit" />
              <CommonTable tableHeaderData={PackagingColDetails}>
                {loadTableData()}
              </CommonTable>
            </Grid.Column>
          ) : null}
        </Grid>
      </CommonModal>
    </>
  );
};

export default ProductDetailModal;
