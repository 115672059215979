import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { successMessage } from "../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi, customConfig } from "./apiService";

interface drawingTool {
  _id: string;
  tenantId: string;
  salesUnitId: string;
  chartNodes: [
    {
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
    }
  ];
  chartEdges: [
    {
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
      style: any;
      markerEnd: any;
      source: string;
      sourceHandle: string;
      target: string;
    }
  ];
}

interface ProductSalesUnits {
  productName: string;
  productId: string;
  salesUnitName: string;
  salesUnitId: string;
}

interface ProductSalesUnitInputs {
  searchKey?: string;
}

export const useGetDrawingToolData = (salesUnitId: string | undefined) =>
  useQuery<drawingTool, ApiServiceErr>(
    [salesUnitId],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${salesUnitId}`,
        customConfig
      );
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!salesUnitId,
    }
  );

export const useCreateDrawing = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/drawing-tool`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useUpdateDrawing = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(`/drawing-tool/${data._id}`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePutDrawingToolDataToQueue = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(`/drawing-tool/queue/${data._id}`);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetProductSalesUnitsNotSavedDrawing = ({
  searchKey,
}: ProductSalesUnitInputs) =>
  useQuery<ProductSalesUnits[], ApiServiceErr>(
    [searchKey, "getProductSalesUnitsNotSavedDrawing"],
    async () => {
      const response = await axiosApi.get(
        "/drawing-tool/sales-units-except-drawing",
        {
          params: { searchKey },
        }
      );
      return response.data;
    }
  );

export const useCopyDrawingToolData = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(
    async ({ drawingId, productId, salesUnitId }) => {
      const response = await axiosApi.post(
        `/drawing-tool/copy/${drawingId}`,
        null,
        {
          params: { productId, salesUnitId },
        }
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );

export const useSendErrorDetails = (
  opt?: UseMutationOptions<
    { message: string },
    ApiServiceErr,
    { productId: string; salesUnitId: string }
  >
) =>
  useMutation<
    { message: string },
    ApiServiceErr,
    {
      productId: string;
      salesUnitId: string;
      companyName: string;
      mappingUrl: string;
    }
  >(
    async ({ productId, salesUnitId, companyName, mappingUrl }) => {
      const response = await axiosApi.post(
        "/drawing-tool/send-error-details",
        { productId, salesUnitId, companyName, mappingUrl },
        customConfig
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        successMessage(data.message);
      },
      ...opt,
    }
  );

export const useUploadMappingImageAction = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/drawing-tool/upload-final-image`,
      data,
      {
        headers: {
          ContentType: "multipart/form-data",
        },
      }
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetMappingImageUri = (imageKey: string) =>
  useQuery<{ signedUrl: string }, ApiServiceErr>(
    [imageKey, "getMappingImageUri"],
    async () => {
      const response = await axiosApi.get("/drawing-tool/image-uri", {
        params: { imageKey },
      });
      return response.data;
    },
    {
      enabled: !!imageKey,
    }
  );
