import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "../../ingredients/ingredientDetails/IngredientDetails.scss";
import { DrawingContext } from "../DrawingTool";
import { SearchValue, TitleView } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  useGetIngredientPackaging,
  useGetIngredientWholeList,
  useGetSupplierIngredientDetail,
  usePutIngredientData,
  usePutSupplierData,
} from "../../../api/ingredients";
import { Dimmer, Grid, Loader, Message, Image } from "semantic-ui-react";
import {
  Node,
  getNodesBounds,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { usePutEditProduct } from "../../../api/product";
import { successMessage } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { without } from "lodash";
import { useGetSupplierDrawingToolData } from "../../../api/supplierDrawingTool";
import { nodeCatagories } from "../../../config/drawingConstants";
import { v4 as uuidv4 } from "uuid";
import { concatToNewArray } from "../../../utils/utils";
import { getNodePositionInsideParent } from "../../supplier_drawing_tool/utils";
import {
  useGetCountries,
  useGetSpecifyIngredient,
} from "../../../api/static-data";
import IngredientPackagingView from "../../ingredients/ingredientRegister/IngredientPackageing";
import IngredientDetailsView from "../../ingredients/ingredientDetails/IngredientDetailsView";
import ProduceIngredientView from "../../ingredients/ingredientDetails/ProduceIngredientView";
import {
  IngredientDataType,
  IngredientType,
} from "../../../config/enums/IngredientType.enum";
import { useGetMappingImageUri } from "../../../api/drawingTool";
import { getUserRolesInLocal } from "../../../utils/cacheStorage";
import { ROLES } from "../../../config/permission-maps";

const initialIngredient = {
  id: null,
  title: "",
  suppliers: [],
  productIds: [],
};

const initialSupplier = {
  id: null,
  title: "",
  supplierData: {
    ingredientIds: [],
    productIds: [],
  },
};

export const IngredientBase = ({ modalData }: any) => {
  const {
    nodeItemId,
    chartEdges,
    chartNodes,
    productId,
    productData,
    setNodeItem,
    setChartNodes,
    setChartEdges,
    saveDrawing,
    saveProductData,
  } = useContext(DrawingContext);
  // get chart node item from node id
  const { deleteElements, getNode } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [ingredient, setIngredient] = useState(initialIngredient);
  const [supplier, setSupplier] = useState(initialSupplier);
  const [visible, setVisible] = useState<boolean>(false);
  const localUserRole = getUserRolesInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  const {
    data: ingredientDetails,
    isLoading: isSuplierIngredientDetailsLoading,
  } = useGetSupplierIngredientDetail(supplier.id || "", ingredient.id || "");
  const isDetailsLoading =
    ingredient.id && supplier.id ? isSuplierIngredientDetailsLoading : false;

  const nodeItem = useMemo(() => getNode(nodeItemId || ""), [nodeItemId]);

  const {
    data: ingredientListByProduct,
    isLoading: isIngredientListByProductLoading,
    isSuccess,
  } = useGetIngredientWholeList(false);

  const { isLoading: editProductLoading, mutate: editProductData } =
    usePutEditProduct();

  const {
    isLoading: updateIngredientDataLoading,
    mutateAsync: mutatePutIngredient,
  } = usePutIngredientData();

  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();

  const { data: specifyIngredients } = useGetSpecifyIngredient();

  const { data: countriesData, isSuccess: isCountriesDataSuccess } =
    useGetCountries();

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  const specifyIngredientName = specifyIngredients?.find(
    (s: any) => s.value === ingredientDetails?.specifyIngredient
  )?.text;

  const countryName = countries?.find(
    (c: any) => parseInt(c.value) === parseInt(ingredientDetails?.countryOrigin)
  )?.text;

  const isSpecific =
    ingredientDetails &&
    ingredientDetails.data_type === IngredientDataType.SUPPLIER;
  const isIngredientDetailsEmpty =
    !ingredientDetails && ingredient.id && supplier.id;

  const {
    isLoading: isLoadingSupplierDrawing,
    data: supplierDrawing,
    isInitialLoading,
  } = useGetSupplierDrawingToolData(
    isSpecific ? ingredient.id ?? "" : "",
    isSpecific ? supplier.id ?? "" : ""
  );

  const isSupplierDrawingExsits = ingredientDetails?.product_summary || null;

  const { data: signedUrlData } = useGetMappingImageUri(
    isSupplierDrawingExsits?.imageKey
  );

  const checkRowOrProcess = useMemo(
    () => ({
      processIngredient:
        ingredientDetails?.ingredient_type === IngredientType.PROCESSED &&
        ingredientDetails?.data_type === IngredientDataType.GENERIC,
      produceIngredient:
        ingredientDetails?.ingredient_type === IngredientType.RAW &&
        ingredientDetails?.data_type === IngredientDataType.GENERIC,
      enableIngredient:
        ingredientDetails?.ingredient_type === IngredientType.RAW &&
        ingredientDetails?.data_type === IngredientDataType.GENERIC,
    }),
    [ingredientDetails?.ingredient_type, ingredientDetails?.data_type]
  );

  const { processIngredient, produceIngredient, enableIngredient } =
    checkRowOrProcess;

  let getPackagingData = {
    ingredientId: ingredient?.id,
    supplierId: supplier?.id,
    loadPackageData: enableIngredient,
  };

  const { data: ingredientPackageData, isLoading: loadIngredientPackageData } =
    useGetIngredientPackaging(getPackagingData);

  useEffect(() => {
    if (isSuccess) {
      const ingredient = ingredients?.find(
        (ing: any) => ing?.id === nodeItem?.data?.reference?.ingredientId
      );
      const supplier = ingredient?.suppliers?.find(
        (sup: any) => sup?._id === nodeItem?.data?.reference?.supplierId
      );
      setIngredient(ingredient || initialIngredient);
      setSupplier({
        id: supplier?._id || null,
        title: supplier?.supplier_name || "",
        supplierData: supplier,
      });
    }
  }, [nodeItem, isSuccess]);

  const { height } = useWindowDimensions();
  const disableEdit =
    ingredient.id && nodeItem?.data.reference && supplier.id ? true : false;

  // ingredients array modifying for dropdown
  const ingredients = useMemo(() => {
    return ingredientListByProduct?.map((pi: any) => {
      return {
        id: pi.ingredientData?._id,
        title: pi.ingredientData?.ingredient_name,
        suppliers: pi.ingredientData?.suppliers,
        productIds: pi.ingredientData?.productIds,
      };
    });
  }, [ingredientListByProduct]);

  // supplier array according to selected ingredient
  const suppliers = useMemo(() => {
    return ingredient?.suppliers?.map((pi: any) => ({
      id: pi._id,
      title: pi.supplier_name,
      supplierData: pi,
    }));
  }, [ingredient]);

  const updateProductData = (status: any) => {
    let checkProductIngredientAvailable = productData.product_ingredients.find(
      (e: any) => e === ingredient.id
    );
    if (
      (checkProductIngredientAvailable && !status) ||
      (!checkProductIngredientAvailable && status)
    )
      return;
    const withoutIngredient = without(
      productData.product_ingredients,
      ingredient.id
    );
    let updateProductIngredient = [
      ...productData.product_ingredients,
      ingredient.id,
    ];
    let productDetails = {
      _id: productData?._id,
      product_ingredients: status ? withoutIngredient : updateProductIngredient,
    };
    editProductData(productDetails, {
      onSuccess(data) {
        saveProductData(data);
      },
    });
  };

  const updateIngredient = (status: any) => {
    let checkProductIngredientAvailable = ingredient?.productIds.some(
      (e: any) => e === productId
    );

    if (checkProductIngredientAvailable && !status) return;
    const withoutProduct = without(ingredient.productIds, productId);
    let updateProduct = [...ingredient.productIds, productId];
    const updateIngredientData = {
      _id: ingredient.id,
      ingredient_name: ingredient.title,
      productIds: status ? withoutProduct : updateProduct,
    };
    mutatePutIngredient(updateIngredientData, {
      onSuccess(data: any) {
        successMessage("Update Ingredient");
      },
    });
  };

  const updateSupplierData = (status: any) => {
    let checkProductIngredientAvailable =
      supplier?.supplierData?.ingredientIds?.some(
        (e: any) => e === ingredient.id
      );

    let checkProductAvailable = supplier?.supplierData?.productIds?.some(
      (e: any) => e === productId
    );

    if (checkProductIngredientAvailable && checkProductAvailable && !status)
      return;

    const withoutProduct = without(ingredient.productIds, productId);

    let productIds = status
      ? withoutProduct
      : [...supplier.supplierData.productIds, productId];

    let supplierDataUpdate = {
      _id: supplier.id,
      supplier_name: supplier?.title,
      productIds: productIds,
    };
    mutatePutSupplier(supplierDataUpdate, {
      onSuccess(data: any) {
        successMessage("Supplier update successfully");
      },
    });
  };

  const deleteIngredient = () => {
    setVisible(true);
  };

  const updateAllData = () => {
    updateProductData(true);
    updateIngredient(true);
    updateSupplierData(true);
  };

  const deleteIngredientNode = () => {
    const supplierDrawingId = supplierDrawing?._id;

    const parentData = chartNodes.find(
      (e) => e?.data?.reference?.supplierDrawingId === supplierDrawingId
    );

    const parenId = parentData?.id;

    if (parenId && supplierDrawingId) {
      const currentNode = chartNodes.filter((e: any) => e.id == nodeItem?.id);
      const getNestedChart = chartNodes.filter(
        (e: any) => e.parentNode == parenId
      );
      const checkNestedChartNode = chartNodes.filter(
        (e: any) =>
          getNestedChart.some((test) => e.parentNode == test.id) ||
          e.id == parenId
      );
      const getGroupNodes = [
        ...getNestedChart,
        ...checkNestedChartNode,
        ...currentNode,
      ].map((node) => ({ id: node.id }));
      updateAllData();
      deleteElements({ nodes: getGroupNodes });
      setNodeItem(null);
    } else {
      if (disableEdit) {
        updateAllData();
      }
      deleteElements({ nodes: [{ id: nodeItem?.id || "" }] });
      setNodeItem(null);
    }
  };

  const getIngredientNodes = (parentNodeId: string) => {
    const ingredientNodes: Node[] =
      supplierDrawing?.chartNodes.map((n, index) => {
        const position = {
          x: n.position.x / (index === 0 ? 4 : 2),
          y: n.position.y / 2,
        };
        if (n.parentNode) {
          return { ...n, position };
        }
        return {
          ...n,
          position,
          extent: "parent",
          parentNode: parentNodeId,
        };
      }) || [];
    return ingredientNodes;
  };

  const createIngredientGate = useCallback(
    (ingredientGateId: string) => {
      const newNodeId = ingredientGateId ? ingredientGateId : uuidv4();
      const ingredientNodes: Node[] = getIngredientNodes(newNodeId);
      const rect = getNodesBounds(ingredientNodes, [1, 1]);
      const nodeStyle = { width: rect.width, height: rect.height };
      const position = {
        x: 0,
        y: 0,
      };
      const newNode: Node = {
        id: newNodeId,
        type: nodeCatagories.ingredientGate,
        data: {
          label: "Ingredient Gate",
          icon: "ingredientGate",
          description: "",
          reference: {
            supplierDrawingId: supplierDrawing?._id,
            lastChangeAt: supplierDrawing?.updatedAt,
          },
        },
        position: position,
        style: nodeStyle,
      };
      return { newNode, ingredientNodes, nodeStyle };
    },
    [supplierDrawing]
  );

  const addingSupplierDrawing = useCallback(
    (updatedChartNodes: Node[], updatedNode: any) => {
      const existingIngredientGate = updatedChartNodes.find(
        (node) =>
          node.data.reference?.supplierDrawingId === supplierDrawing?._id
      );
      const { newNode, ingredientNodes, nodeStyle } = createIngredientGate(
        existingIngredientGate?.id || ""
      );
      const nextNodes: Node[] = [
        newNode,
        ...ingredientNodes.map((n) => {
          const position = getNodePositionInsideParent(
            JSON.parse(JSON.stringify(n)),
            { ...nodeStyle, ...newNode }
          );
          return { ...n, position };
        }),
        ...updatedChartNodes,
      ];
      setChartNodes(nextNodes);
      updateNodeInternals(
        concatToNewArray([newNode, ...ingredientNodes], "id")
      );
      setChartEdges([...chartEdges, ...(supplierDrawing?.chartEdges || [])]);
      setNodeItem(updatedNode);
      saveDrawing(chartEdges, nextNodes);
    },
    [
      chartNodes,
      chartEdges,
      setChartNodes,
      setNodeItem,
      saveDrawing,
      createIngredientGate,
    ]
  );

  // submit callback function
  const onSubmit = useCallback(() => {
    updateProductData(false);
    updateIngredient(false);
    updateSupplierData(false);
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: ingredient.title,
        // reference is mongoose mix type so you can set any type of here please set necessary reference only
        reference: {
          ingredientId: ingredient.id,
          supplierId: supplier.id,
        },
        description: `${ingredient.title} ${supplier.title}`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });

    if (
      supplierDrawing?._id &&
      !(ingredient.id && nodeItem?.data.reference && supplier.id)
    ) {
      addingSupplierDrawing(updatedChartNodes, updatedNode);
      return;
    }
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  }, [
    nodeItem,
    chartEdges,
    chartNodes,
    ingredient,
    supplier,
    addingSupplierDrawing,
  ]);

  // Loading state check
  if (
    isIngredientListByProductLoading ||
    editProductLoading ||
    updateIngredientDataLoading ||
    isDetailsLoading ||
    (isInitialLoading && isLoadingSupplierDrawing)
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const handleIngredientValidation = (status: any) => {
    if (status) {
      setIngredient(initialIngredient);
      setSupplier(initialSupplier);
    }
  };

  const handleSupplierValidation = (status: any) => {
    if (status) {
      setSupplier(initialSupplier);
    }
  };

  return (
    <>
      <div
        style={{
          height: height * 0.9 - 100,
          overflowX: "hidden",
        }}
      >
        <>
          <SearchValue
            searchDataValues={ingredients}
            title={"Ingredient Name*"}
            validationMessage="Please Select ingredient"
            checkOther={(status: any) => {
              handleIngredientValidation(status);
            }}
            defaultValue={ingredient.title}
            selectDetails={({ result }: any) => {
              setIngredient(result?.other);
              setSupplier(initialSupplier);
            }}
            customMainView="customMainViewIngredientSearch"
          />
          {ingredient.id ? (
            <SearchValue
              searchDataValues={suppliers}
              title={"Supplier Name*"}
              validationMessage="Please Select supplier"
              checkOther={(status: any) => {
                handleSupplierValidation(status);
              }}
              defaultValue={supplier.title}
              selectDetails={({ result }: any) => {
                setSupplier(result?.other);
              }}
            />
          ) : null}
        </>
        {ingredientDetails ? (
          <>
            <div
              style={{
                height: "20px",
                overflowX: "hidden",
              }}
            ></div>
            <TitleView
              CustomTitleViewMain="customTileViewHeader"
              title="Ingredient information"
            />
            {isSupplierDrawingExsits ? (
              <Message className="climateImpactMessage">
                Total impact climate change :{" "}
                <span className="climateImpactValue">
                  {checkIsUserSupplier
                    ? "Calculated: Upgrade to see results"
                    : isSupplierDrawingExsits.displayTotalImpactClimateChange}
                </span>
              </Message>
            ) : null}
            <IngredientDetailsView
              ingredient_type={ingredientDetails?.ingredient_type}
              data_type={ingredientDetails?.data_type}
              unit_size={ingredientDetails?.unit_size}
              unit_type={ingredientDetails?.unit_type}
              ean_bar_code={ingredientDetails?.ean_bar_code}
            />
            {!isSpecific ? (
              <TitleView
                CustomTitleViewMain="customTileViewHeader"
                title={
                  processIngredient
                    ? "Processed ingredient information"
                    : "Produce Information"
                }
              />
            ) : null}
            {produceIngredient ? (
              <ProduceIngredientView
                produceType={ingredientDetails?.produceType}
                produceCultivar={ingredientDetails?.produceCultivar}
                farmingType={ingredientDetails?.farmingType}
                fieldType={ingredientDetails?.fieldType}
                countryName={countryName}
              />
            ) : null}
            {processIngredient ? (
              <Grid className="dataBox">
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <p className="mb-0">Specify Ingredient</p>
                  {specifyIngredientName}
                </Grid.Column>

                {!isSpecific ? (
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <p className="mb-0">Country</p>
                    {countryName}
                  </Grid.Column>
                ) : null}
              </Grid>
            ) : null}
            {enableIngredient ? (
              <>
                <div
                  style={{
                    height: "10px",
                    overflowX: "hidden",
                  }}
                ></div>
                <IngredientPackagingView
                  ingredientId={ingredient?.id}
                  supplierId={supplier?.id}
                  ingredientPackageData={ingredientPackageData}
                  dataView={true}
                />
              </>
            ) : null}
          </>
        ) : isIngredientDetailsEmpty ? (
          <Message color="red">
            No Supplier Ingredient Details Available. Please check if the
            supplier ingredient details are correctly set.
          </Message>
        ) : null}
        {signedUrlData ? (
          <Image src={signedUrlData?.signedUrl} size="large" rounded />
        ) : null}
      </div>
      <MainBottomButtonView
        saveButtonStatus={true}
        deleteStatus={true}
        saveTitle={
          nodeItem?.data.reference && ingredient.id ? "Update" : "Save"
        }
        saveButton={() => onSubmit()}
        deleteButton={() => deleteIngredient()}
        type="submit"
      />
      <ConfirmModal
        viewModal={visible}
        closeModal={() => setVisible(false)}
        cancel={() => {
          setVisible(false);
        }}
        approve={() => {
          deleteIngredientNode();
        }}
        title="Delete Ingredient"
        subTitle="Are you sure you want to remove the ingredient data?"
      />
    </>
  );
};
