export const ROLES = {
  rootAdmin: "Root Admin",
  groupAdmin: "Group Admin",
  admin: "Admin",
  user: "User",
  supplier: "Supplier",
};

export const SCOPES = {
  canAccessInHeaderMenu: "can-access-in-header-menu",
  canAccessInSideMenu: "can-access-in-side-menu",
  canCreate: "can-create",
  canEdit: "can-edit",
  canDelete: "can-delete",
  canView: "can-view",
  canCalculate: "can-calculate",
};

export const FEATURES = {
  dashboard: "dashboard",
  product: "product",
  supplier: "supplier",
  facility: "facility",
  drawing: "drawing",
  productSummary: "productSummary",
  drawingRequests: "drawingRequests",
  ingredient: "ingredient",
  user: "user",
  role: "role",
  company: "company",
  companySearch: "companySearch",
  logout: "logout",
  staticConfiguration: "staticConfiguration",
};

export const PERMISSIONS = {
  [ROLES.rootAdmin]: [
    {
      name: FEATURES.dashboard,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.product,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.ingredient,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.supplier,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.facility,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawing,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.productSummary,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.user,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
    {
      name: FEATURES.company,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
    {
      name: FEATURES.drawingRequests,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.companySearch,
      scopes: [SCOPES.canAccessInHeaderMenu, SCOPES.canView],
    },
    {
      name: FEATURES.staticConfiguration,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
  ],
  [ROLES.groupAdmin]: [
    {
      name: FEATURES.dashboard,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.product,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.ingredient,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.supplier,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.facility,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawing,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.productSummary,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.user,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
    {
      name: FEATURES.company,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
    {
      name: FEATURES.companySearch,
      scopes: [SCOPES.canAccessInHeaderMenu, SCOPES.canView],
    },
    {
      name: FEATURES.drawingRequests,
      scopes: [SCOPES.canAccessInSideMenu],
    },
  ],
  [ROLES.admin]: [
    {
      name: FEATURES.dashboard,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.product,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.ingredient,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.supplier,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.facility,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawing,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.productSummary,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.user,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
    {
      name: FEATURES.companySearch,
      scopes: [SCOPES.canAccessInHeaderMenu, SCOPES.canView],
    },
    {
      name: FEATURES.drawingRequests,
      scopes: [SCOPES.canAccessInSideMenu],
    },
  ],
  [ROLES.user]: [
    {
      name: FEATURES.dashboard,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.product,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.ingredient,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.supplier,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.facility,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawing,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.productSummary,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.companySearch,
      scopes: [SCOPES.canAccessInHeaderMenu, SCOPES.canView],
    },
    {
      name: FEATURES.drawingRequests,
      scopes: [SCOPES.canAccessInSideMenu],
    },
  ],
  [ROLES.supplier]: [
    {
      name: FEATURES.product,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.ingredient,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.supplier,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.facility,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawing,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.drawingRequests,
      scopes: [SCOPES.canAccessInSideMenu],
    },
    {
      name: FEATURES.companySearch,
      scopes: [SCOPES.canAccessInHeaderMenu, SCOPES.canView],
    },
    {
      name: FEATURES.user,
      scopes: [
        SCOPES.canAccessInHeaderMenu,
        SCOPES.canCreate,
        SCOPES.canEdit,
        SCOPES.canDelete,
        SCOPES.canView,
      ],
    },
  ],
};
