import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Dimmer,
  Grid,
  Loader,
  Table,
  GridRow,
  Accordion,
  AccordionTitle,
  AccordionContent,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  InputText,
  CustomButton,
  CommonTable,
  DropDown,
  AccordionTitleView,
} from "../../../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { CultivationPackagingView } from "../../../../../config/constants";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import {
  useCreatePackaging,
  useDeletePackaging,
  useUpdatePackaging,
} from "../../../../../api/processGate/packaging";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import "./packaging.scss";
import { valueGraterThanZero } from "../../../../../utils/utils";
import _ from "lodash";
import {
  removeNewDrawingItemStatus,
  saveNewDrawingItem,
} from "../../../../../utils/cacheStorage";

const ProcessPackaging = ({
  packagingData,
  refetchData,
  packagingType,
  processGateId,
  packagingId,
  savePackingNodeData,
  packagingTypeData,
  totalWeightOfItem = 0,
  unitSizes,
  palletLength,
  palletWidth,
  changeUnits,
  setIsSaveBtnClicked,
  isSaveBtnClicked,
  setClickedPackagingUnit,
  clickedPackagingUnit,
  packagingName,
  onPackagingSave = (packagingId: string) => {},
}: any) => {
  const [enableSizeEditStatus, setEnableSizeEditStatus] = useState<any>(false);
  const [defaultType, setDefaultType] = useState<any>(0);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [packageId, setPackageId] = useState(null);
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const lastEditedTextboxRef = useRef<any>(null);

  const {
    register: packaging,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitSingleUnit,
    control,
    reset,
    setValue,
    setError,
    watch,
  } = useForm();

  const loadPackageUnitDetails = () => {
    if (getValuesByPackagingType()?.name) {
      if (
        isSaveBtnClicked &&
        getValuesByPackagingType()?.name === clickedPackagingUnit
      ) {
        lastEditedTextboxRef.current = getValuesByPackagingType()?.name;
        scrollToLastEditedTextbox();
        setIsSaveBtnClicked(false);
        setClickedPackagingUnit("baseUnit");
      }
      setValue(`${getValuesByPackagingType()?.name}`, unitSizes);
      if (
        packagingType === "transport-unit" ||
        clickedPackagingUnit === "numCases"
      ) {
        setValue(`palletWidth`, palletWidth);
        setValue(`palletLength`, palletLength);
      }
    }
  };

  useEffect(() => {
    loadPackageUnitDetails();
  }, [unitSizes, palletWidth, palletLength, packagingData]);

  //custom hook create packaging
  const { isLoading, mutate } = useCreatePackaging();

  //custom hook update packaging
  const { isLoading: isLoadingUpdate, mutate: updatePackaging } =
    useUpdatePackaging();

  //custom hook delete packaging
  const { isLoading: isDeletePackaging, mutate: deletePackage } =
    useDeletePackaging();

  const setUnitSizeUpdate = (data: any) => {
    setValue("packageName", data.packageName);
    setValue("materialWeight", data.materialWeight);
    setValue("materialCost", data.materialCost);
    setDefaultType(data?.packageMaterial || 0);
  };

  //delete package data
  const deletePackageData = (data: any) => {
    const deletePackagingData = {
      packagingType,
      processGateId,
      packagingId,
      subPackagingId: data._id,
    };
    deletePackage(deletePackagingData, {
      onSuccess() {
        onPackagingSave(data.packagingId);
        refetchData();
        successMessage("Package deleted successfully");
      },
    });
  };

  const commentResetValue = (statusMessage: any) => {
    reset();
    setDefaultType(0);
    setEnableSizeEditStatus(false);
    successMessage(statusMessage);
    setPackageId(null);
  };

  //api call add to package data
  const addPackageData = (packageData: any) => {
    mutate(packageData, {
      onSuccess: (data) => {
        onPackagingSave(data.packagingId);
        saveNewDrawingItem("created");
        commentResetValue("Package created successfully");
        if (packagingId) {
          refetchData(packagingId);
          return;
        }
        savePackingNodeData(data.packagingId);
      },
    });
  };

  //api call edit to package data
  const editPackageData = (packageData: any) => {
    updatePackaging(packageData, {
      onSuccess: (data) => {
        onPackagingSave(data.packagingId);
        removeNewDrawingItemStatus();
        commentResetValue("Package updated successfully");
        refetchData();
      },
    });
  };

  const getValuesByPackagingType = () => {
    switch (packagingType) {
      case "single-unit":
        return {
          title: "Base unit packaging",
          label: "Unit size (kg)",
          type: "Base unit",
          name: "baseUnit",
        };
      case "sales-unit":
        return {
          title: "Pack or inner pack packaging",
          label: "Number of Base Units",
          type: "Pack",
          name: "numBaseUnit",
        };
      case "bulk-unit":
        return {
          title: "Case packaging",
          label: "Number of Packs",
          type: "Case",
          name: "numPacks",
        };
      case "transport-unit":
        return {
          title: "Pallet packaging",
          label: "Number of Cases",
          type: "Pallet",
          name: "numCases",
        };
    }
  };

  const palletUnitName = getValuesByPackagingType()?.name;

  const palletWidthData = watch("palletWidth");
  const palletLengthData = watch("palletLength");
  const palletUnitData = watch(getValuesByPackagingType()?.name || "");

  const saveTransportUnitDataValidation = useMemo(() => {
    if (
      unitSizes == palletUnitData &&
      palletLength == palletLengthData &&
      palletWidth == palletWidthData
    ) {
      return false;
    }
    if (palletUnitData > 0 && palletWidthData > 0 && palletLengthData > 0) {
      return true;
    }
  }, [palletUnitData, palletWidthData, palletLengthData]);

  const handlePackageValidation = () => {
    const packagingName = getValuesByPackagingType()?.name || "";
    const packagingNameDetails = getValuesByPackagingType()?.title || "";
    if (packagingType !== "transport-unit" && unitSizes === 0) {
      setError(`${packagingName}`, {
        type: "required",
        message: `${packagingNameDetails} is required`,
      });
      errorView(`${packagingNameDetails} is required`);
      return false;
    }
    if (unitSizes === 0 || palletLength === 0 || palletWidth === 0) {
      setError(`${packagingName}`, {
        type: "required",
        message: `${packagingNameDetails} No of Cases , Width and Length is required`,
      });
      errorView(
        `${packagingNameDetails} No of Cases , Width and Length is required`
      );
      return false;
    }

    return true;
  };

  const enableOtherUnitSaving = useMemo(() => {
    return palletUnitData > 0 && unitSizes != palletUnitData;
  }, [palletUnitData]);

  //handle package data view
  const packageDataInsert = (data: any, status: any) => {
    data.packageMaterial = defaultType;
    data.contentWeight = 0;
    data.totalWeightWithPackaging = 0;
    data.materialWeight = Number(data.materialWeight);
    data.materialCost = Number(data.materialCost);
    let packageData = {
      data,
      packagingType,
      processGateId,
      packagingId: status === "add" ? packagingId : packageId,
    };
    if (handlePackageValidation()) {
      if (status === "add") {
        addPackageData(packageData);
      } else {
        editPackageData(packageData);
      }
    }
  };

  const handleClick = () => {
    if (!packagingName) return;
    setAccordionEnabled(!accordionEnabled);
  };

  const loadTableData = () => {
    return packagingData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.packageName ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{packagingTypeData?.[data?.packageMaterial || 0]?.text || ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialWeight ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialCost ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setUnitSizeUpdate(data);
                    setEnableSizeEditStatus(true);
                    setPackageId(data._id);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  if (isLoading || isLoadingUpdate || isDeletePackaging) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const scrollToLastEditedTextbox = () => {
    if (lastEditedTextboxRef.current) {
      const lastEditedTextbox = document.getElementById(
        `${lastEditedTextboxRef.current}`
      );

      if (lastEditedTextbox) {
        lastEditedTextbox.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onchangeDebounce = _.debounce((value) => changeUnits(value), 1000);

  const saveTransportUnitValue = () => {
    setIsSaveBtnClicked(true);
    setClickedPackagingUnit(getValuesByPackagingType()?.name);
    if (packagingType === "transport-unit") {
      const packageData = {
        palletWidth: palletWidthData,
        palletLength: palletLengthData,
        packageUnitValue: palletUnitData,
      };
      onchangeDebounce(packageData);
    } else {
      onchangeDebounce(palletUnitData);
    }
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          isDisabled={!packagingName}
          accordionEnabled={accordionEnabled}
          title={getValuesByPackagingType()?.title}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <Grid>
          <GridRow>
            <Grid.Column floated="left" computer={5} tablet={8} mobile={16}>
              <InputNumber
                id={getValuesByPackagingType()?.name}
                transform={{
                  input: (value: number) =>
                    isNaN(value) || value === 0 ? "" : value,
                  output: (e: number) => {
                    return e;
                  },
                }}
                control={control}
                errors={errorsUnit[`${palletUnitName}`]}
                labelName={getValuesByPackagingType()?.label}
                type="number"
                placeholder={
                  packagingData && packagingData.length > 0 ? 0 : "0"
                }
                name={getValuesByPackagingType()?.name}
                otherValidation={{
                  validate: {
                    valueCheck: (v: any) =>
                      valueGraterThanZero(v) ||
                      `${
                        getValuesByPackagingType()?.label
                      } must be grater than 0`,
                  },
                }}
              />
            </Grid.Column>
            {packagingType === "transport-unit" ? (
              <>
                <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: number) =>
                        isNaN(value) || value === 0 ? "" : value,
                      output: (e: number) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errorsUnit.palletWidth}
                    labelName={"Pallet Width"}
                    placeholder={
                      packagingData && packagingData.length > 0
                        ? 0
                        : "width (cm)"
                    }
                    name="palletWidth"
                    customInputValidation={true}
                    otherValidation={{
                      validate: {
                        valueCheck: (v: any) =>
                          valueGraterThanZero(v) ||
                          "Pallet Width must be grater than 0 ",
                      },
                    }}
                  />
                </Grid.Column>
                <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                  <label className={`textLabelBlind`}>{`+`}</label>
                  <InputNumber
                    transform={{
                      input: (value: number) =>
                        isNaN(value) || value === 0 ? "" : value,
                      output: (e: number) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errorsUnit.palletLength}
                    register={packaging}
                    labelName={"Pallet Length"}
                    placeholder={
                      packagingData && packagingData.length > 0
                        ? 0
                        : "length (cm)"
                    }
                    name="palletLength"
                    otherValidation={{
                      validate: {
                        valueCheck: (v: any) =>
                          valueGraterThanZero(v) ||
                          "Pallet Length must be grater than 0 ",
                      },
                    }}
                  />
                </Grid.Column>

                {saveTransportUnitDataValidation ? (
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <CustomButton
                      theme="green"
                      customColumnStyle={"addUnitSize"}
                      title={
                        unitSizes > 0 ||
                        (packagingType === "transport-unit" &&
                          (palletLength > 0 || palletWidth > 0))
                          ? "Update"
                          : "Save"
                      }
                      onClick={() => {
                        saveTransportUnitValue();
                      }}
                    />
                  </Grid.Column>
                ) : null}
              </>
            ) : null}
            {packagingType !== "transport-unit" && (
              <>
                {enableOtherUnitSaving ? (
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <CustomButton
                      theme="green"
                      customColumnStyle={"addUnitSize"}
                      title={unitSizes > 0 ? "Update" : "Save"}
                      onClick={() => {
                        setIsSaveBtnClicked(true);
                        setClickedPackagingUnit(
                          getValuesByPackagingType()?.name
                        );
                        onchangeDebounce(palletUnitData);
                      }}
                    />
                  </Grid.Column>
                ) : null}
                <Grid.Column
                  floated="right"
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <label className={`textLabel`}>{`Total weight of ${
                    getValuesByPackagingType()?.type
                  }`}</label>
                  <br />
                  <label className={`textLabel`}>{totalWeightOfItem}</label>
                </Grid.Column>
              </>
            )}
          </GridRow>
          {packagingType === "transport-unit" && (
            <GridRow>
              <Grid.Column floated="left" computer={5} tablet={8} mobile={16}>
                <label className={`textLabel`}>{`Total weight of ${
                  getValuesByPackagingType()?.type
                }`}</label>
                <br />
                <label className={`textLabel`}>{totalWeightOfItem}</label>
              </Grid.Column>
            </GridRow>
          )}

          <GridRow className="contentMargin">
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={packaging}
                errors={errorsUnit.packageName}
                labelName={"Packaging Name"}
                placeholder="Packaging Name"
                name="packageName"
                required={true}
                errorMessage="Packaging name is required"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <DropDown
                labelName={"Packaging Material"}
                placeholder="Packaging Material"
                currentData={packagingTypeData}
                defaultValue={packagingTypeData?.[defaultType]?.value || []}
                customGridColumn={"customGridColomnType"}
                handleChangeState={(e: any, { value }: any) => {
                  let getIndex = packagingTypeData?.findIndex(
                    (e: any) => e?.value === value
                  );
                  setDefaultType(getIndex);
                }}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <InputNumber
                control={control}
                errors={errorsUnit.materialWeight}
                labelName={"Material Weight(kg)"}
                placeholder="0.00"
                name="materialWeight"
                required={true}
                errorMessage="Material Weight is required"
                otherValidation={{
                  validate: {
                    valueCheck: (v: any) =>
                      valueGraterThanZero(v) ||
                      "Material weight must be grater than 0",
                  },
                }}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <InputNumber
                control={control}
                errors={errorsUnit.materialCost}
                labelName={"Material Cost per unit"}
                placeholder="0.00"
                name="materialCost"
                required={false}
                errorMessage="Material Cost is required"
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={8} mobile={16}>
              {enableSizeEditStatus ? (
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  title="Update"
                  onClick={handleSubmitSingleUnit((data) => {
                    packageDataInsert(data, "edit");
                  })}
                />
              ) : (
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  icon="plus"
                  onClick={handleSubmitSingleUnit((data) => {
                    packageDataInsert(data, "add");
                  })}
                />
              )}
            </Grid.Column>
          </GridRow>
        </Grid>
        {packagingData?.length > 0 && (
          <Grid.Column
            computer={16}
            tablet={16}
            mobile={16}
            className="packagingDetailsView"
          >
            <CommonTable tableHeaderData={CultivationPackagingView}>
              {loadTableData()}
            </CommonTable>
          </Grid.Column>
        )}
      </AccordionContent>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deletePackageData(deleteData);
        }}
        title="Delete package data"
        subTitle="Are you sure you want to delete packaging data?"
      />
    </Accordion>
  );
};

export default ProcessPackaging;
